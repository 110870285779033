import React from "react";
import styles from "./index.module.css";
import Layout from "../components/layout";
import { AppLogo } from "../components/app-logo";
import { APPS } from "../components/config";

export default () => (
  <Layout>
    <h1 className={styles.title}>Choose your radio</h1>
    <div className={styles.apps}>
      {Object.keys(APPS).map(cc => (
        <AppLogo key={cc} countryCode={cc} />
      ))}
    </div>
  </Layout>
);
